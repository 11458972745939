<home-toolbar
  [showBackButton]="true"
  [title]="'overdracht' | translate"
></home-toolbar>

<ion-content>
  <div class="d-flex flex-column justify-content-between h-100">
    <form
      [formGroup]="form"
      (ngSubmit)="submit(form.value)"
      novalidate
      autocomplete="off"
    >
      <div class="container">
        <div class="row">
          <div class="col mb-md-2 mb-sm-2 mb-5">
            <!-- Overdracht -->
            <h1 class="text-uppercase mb-3">{{ "overdracht" | translate }}</h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "plaats" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="city"
                  name="city"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "Datum" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="position-relative col p-0 mb-0">
                  <ion-datetime-button
                    name="dateButton"
                    id="dateButton"
                    datetime="date"
                    class="input-text justify-content-start flex-nowrap text-nowrap"
                  ></ion-datetime-button>
                  <ion-icon
                    class="icon-calendar"
                    name="calendar"
                    (click)="dateModal.present()"
                  ></ion-icon>
                  <ion-modal [keepContentsMounted]="true" #dateModal>
                    <ng-template>
                      <ion-datetime
                        id="date"
                        presentation="date"
                        name="date"
                        formControlName="date"
                        [cancelText]="'annuleren' | translate"
                        [doneText]="'klaar' | translate"
                        [showDefaultButtons]="true"
                        [locale]="currentLanguage"
                        [formatOptions]="{
                          date: {
                            weekday: 'short',
                            month: 'long',
                            day: '2-digit',
                          },
                          time: {
                            hour: '2-digit',
                            minute: '2-digit',
                          },
                        }"
                      >
                        <span slot="time-label">{{ "time" | translate }}</span>
                      </ion-datetime>
                    </ng-template>
                  </ion-modal>
                </label>
              </div>
            </div>
            <!-- Eind overdracht -->

            <!-- Lading -->
            <h1 class="text-uppercase mb-3">{{ "lading" | translate }}</h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "reis_orders" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="travelOrders"
                  name="travelOrders"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "ladingspapieren" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="loadingPapers"
                  name="loadingPapers"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "ladingssituatie" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="loadingSituation"
                  name="loadingSituation"
                />
              </div>
            </div>


            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "toestandladingstanks" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="stateChargeTanks"
                  name="stateChargeTanks"
                />
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "leidingen_slangen" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="pipesHoses"
                  name="pipesHoses"
                />
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "reinigen_gasvrij" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="cleaningGasFree"
                  name="cleaningGasFree"
                />
              </div>
            </div>
            <!-- Eind lading -->

            <!-- Slobtanks -->
            <h1 *ngIf="shipType == handOverFormType.Tanker" class="text-uppercase mb-3">{{ "slobtanks" | translate }}</h1>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "slobtanks_bb" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="slobtanksBb"
                  name="slobtanksBb"
                />
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "slobtanks_mid" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="slobtanksMid"
                  name="slobtanksMid"
                />
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "slobtanks_sb" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="slobtanksSb"
                  name="slobtanksSb"
                />
              </div>
            </div>
            <!-- Eind Slobtanks -->

            <!-- Spoelwatertanks -->
            <h1 *ngIf="shipType == handOverFormType.Tanker" class="text-uppercase mb-3">{{ "rinsingWaterTank" | translate }}</h1>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "RinsingWaterTank_bb" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="rinsingWaterTankBb"
                  name="rinsingWaterTankBb"
                />
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "RinsingWaterTank_sb" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="rinsingWaterTankSb"
                  name="rinsingWaterTankSb"
                />
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "RinsingWaterTank_Forepeak" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="rinsingWaterTankPeak"
                  name="rinsingWaterTankPeak"
                />
              </div>
            </div>
            <!-- Eind Spoelwatertanks -->

            <!-- ballastBunkers -->
            <h1 class="text-uppercase mb-3">{{ "ballastBunkers" | translate }}</h1>
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "ballast" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="rinsingWaterTankBallast"
                  name="rinsingWaterTankBallast"
                />
              </div>
            </div>
            <!-- Eind ballastBunkers -->

            <!-- Bunker -->
            <h1 class="text-uppercase mb-3 mt-4">{{ "bunker" | translate }}</h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "voor" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  type="number"
                  formControlName="bunkerFront"
                  name="bunkerFront"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "achter" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  type="number"
                  formControlName="bunkerRear"
                  name="bunkerRear"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "besteld" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  type="number"
                  formControlName="bunkerOrdered"
                  name="bunkerOrdered"
                />
              </div>
            </div>

            <!-- TODO: koppelverband -->

            <!-- Eind bunker -->

            <!-- Drinkwater -->
            <h1 class="text-uppercase mb-3 mt-4">
              {{ "drinkwater" | translate }}
            </h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "voor" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  type="number"
                  formControlName="drinkWaterFront"
                  name="drinkWaterFront"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "achter" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  type="number"
                  formControlName="drinkWaterRear"
                  name="drinkWaterRear"
                />
              </div>
            </div>
            <!-- Eind drinkwater -->
          </div>

          <div class="col-lg-6 mb-5">
            <!-- Nautische informatie -->
            <h1 class="text-uppercase mb-3 mt-4">
              {{ "nautische_informatie" | translate }}
            </h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "nautische_apparatuur" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="nauticalEquipment"
                  name="nauticalEquipment"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "manoeuvreer_eigenschappen" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="movementQualities"
                  name="movementQualities"
                />
              </div>
            </div>
            <!-- Eind nautische informatie -->

            <!-- Technische informatie -->
            <h1 class="text-uppercase mb-3 mt-4">
              {{ "technische_informatie" | translate }}
            </h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "tankniveau_apparatuur" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="tankLevelEquipment"
                  name="tankLevelEquipment"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "pompen" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="pumps"
                  name="pumps"
                />
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "motoren" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="engines"
                  name="engines"
                />
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "ketel" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="boiler"
                  name="boiler"
                />
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "tracing" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="tracing"
                  name="tracing"
                />
              </div>
            </div>
            <!-- Eind technische informatie -->

            <!-- Certificaten -->
            <h1 class="text-uppercase mb-3 mt-4">
              {{ "certificaten" | translate }}
            </h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "certificaten_geldig" | translate
              }}</label>
              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <ion-checkbox
                  mode="md"
                  formControlName="certificatesValid"
                  name="certificatesValid"
                  checked="false">
                </ion-checkbox>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "veiligheidsuitrusting_in_orde" | translate
              }}</label>
              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <ion-checkbox
                  mode="md"
                  formControlName="safetyEquipmentValid"
                  name="safetyEquipmentValid"
                  checked="false"
                >
                </ion-checkbox>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "opmerkingen" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <textarea
                  class="input-text col p-0"
                  formControlName="remarks"
                  name="remarks"
                ></textarea>
              </div>
            </div>
            <!-- Eind certificaten -->

            <!-- Overig -->
            <h1 class="text-uppercase mb-3 mt-4">{{ "overig" | translate }}</h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "materialen_besteld" | translate
              }}</label>
              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <ion-checkbox
                  mode="md"
                  formControlName="materialsOrdered"
                  name="materialsOrdered"
                  checked="false"
                >
                </ion-checkbox>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "bijzonderheden" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <textarea
                  class="input-text col p-0"
                  formControlName="particularities"
                  name="particularities"
                ></textarea>
              </div>
            </div>
            <!-- Eind overig -->
          </div>
        </div>
        <div class="row">
          <h1 class="text-uppercase mb-3 mt-4 ml-3">{{ "bemanningsWissel" | translate }}</h1>

          <div class="col-12">

            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"Crewmatrix_bijgewerkt" | translate}}</label>
              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <ion-checkbox
                  mode="md"
                  formControlName="crewMatrixUpdated"
                  name="crewMatrixUpdated"
                  checked="false">
                </ion-checkbox>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.DryLoad">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "onderhoudssyteem_bijgewerkt" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <ion-checkbox
                  mode="md"
                  formControlName="maintenanceUpdated"
                  name="maintenanceUpdated"
                  checked="false">
                </ion-checkbox>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.DryLoad">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "reisverslagen_bijgewerkt" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <ion-checkbox
                  mode="md"
                  formControlName="journeyReportUpdated"
                  name="journeyReportUpdated"
                  checked="false">
                </ion-checkbox>
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.DryLoad">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "reparatielijst_bijgewerkt" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <ion-checkbox
                  mode="md"
                  formControlName="repairListUpdated"
                  name="repairListUpdated"
                  checked="false">
                </ion-checkbox>
              </div>
            </div>

          </div>

          <div class="col-lg-6">
            <div class="row align-items-center mb-3 mr-sm-0">
              <div class="col-md-11 col-sm-12 col-12 pr-sm-0">
                <h1 class="text-uppercase mb-3 smaller">
                  {{ "handtekening_kapitein_aanboord" | translate }}
                </h1>

                <div class="row align-items-center mb-2 mr-sm-0">
                  <label class="label col-md-5 col-sm-6 col-12">{{
                    "naam_kapitein_aanboord" | translate
                  }}</label>

                  <div class="col-md col-sm-6 col-12 p-sm-0">
                    <input
                      class="input-text col p-0"
                      formControlName="nameCaptainOnboard"
                      name="nameCaptainOnboard"
                    />
                  </div>
                </div>

                <signature
                  (onChange)="updateSignature('signatureCaptainOnboard', $event)"
                  [value]="form.get('signatureCaptainOnboard')?.value"
                ></signature>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row align-items-center mb-3 mr-sm-0">
              <div class="col-md-11 col-sm-12 col-12 pr-sm-0">
                <h1 class="text-uppercase mb-3 smaller">
                  {{ "handtekening_kapitein_vanboord" | translate }}
                </h1>

                <div class="row align-items-center mb-2 mr-sm-0">
                  <label class="label col-md-5 col-sm-6 col-12">{{
                    "naam_kapitein_vanboord" | translate
                  }}</label>

                  <div class="col-md col-sm-6 col-12 p-sm-0">
                    <input
                      class="input-text col p-0"
                      formControlName="nameCaptainOffboard"
                      name="nameCaptainOffboard"
                    />
                  </div>
                </div>

                <signature
                  (onChange)="updateSignature('signatureCaptainOffboard', $event)"
                  [value]="form.get('signatureCaptainOffboard')?.value"
                ></signature>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6"></div>
          <div class="col">
            <div class="row align-items-center mb-3 mr-sm-0">
              <div class="col-md-11 col-sm-12 col-12 pr-sm-0">
                <div class="row">
                  <div class="col">
                    <a class="btn-login" (click)="saveReport()">
                      {{ "Opslaan" | translate }}
                    </a>
                  </div>
                  <div class="col">
                    <button
                      class="btn-login"
                      [disabled]="isSaving"
                      type="submit"
                    >
                      {{ "versturen" | translate }}
                    </button>
                  </div>
                </div>

                <span class="small-txt" *ngIf="!form.valid">{{
                  "nietallesingevuld" | translate
                }}</span>

                <div *ngIf="showErrors && !form.valid">
                  <div>Errors</div>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('date')!.hasError('required')"
                  >
                    {{ "datum" | translate }} {{ "incorrect" | translate }}
                  </span>                  
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('nameCaptainOnboard')!.hasError('required')"
                  >
                    {{ "naam_kapitein_aanboord" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="
                      form.get('signatureCaptainOnboard')!.hasError('required')
                    "
                  >
                    {{ "handtekening_kapitein_vanboord" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="
                      form.get('nameCaptainOffboard')!.hasError('required')
                    "
                  >
                    {{ "naam_kapitein_vanboord" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="
                      form.get('signatureCaptainOffboard')!.hasError('required')
                    "
                  >
                    {{ "handtekening_kapitein_aanboord" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ion-content>