import { Component } from '@angular/core';

import { Platform, NavController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './services/authentication.service';
import { LocalAppSettingsService } from './services/local-app-settings.service';
import { NetworkService, ConnectionStatus } from './services/network.service';
import { OfflineEventService } from './services/offline-event.service';
import { Disposable } from './shared/classes/Disposable';
import { FcmService } from './services/fcm.service';
import { Constants } from './constants';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Haptics } from '@capacitor/haptics';
import { Capacitor } from '@capacitor/core';
import { ChooseShipService } from './home/choose-ship/choose-ship.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent extends Disposable {
  constructor(
    private platform: Platform,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private navController: NavController,
    private appSettings: LocalAppSettingsService,
    private networkService: NetworkService,
    private offlineEventService: OfflineEventService,
    private fcm: FcmService,
    private chooseShipService: ChooseShipService
  ) {
    super();

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
      this.notificationSetup();
      
      if (Capacitor.isNativePlatform()) {
        StatusBar?.setBackgroundColor({ color: '#002431' });
      }

      this.translateService.setDefaultLang(this.appSettings.selectedLanguage);

      this.subscriptions.push(
        this.networkService.onNetworkChange().subscribe((status) => {
          if (status === ConnectionStatus.Online) {
            this.subscriptions.push(
              this.offlineEventService.checkForEvents().subscribe()
            );
          }
        })
      );

      this.navigateToInitialRoute();
      this.keepLoginAlive();
    });
  }

  private notificationSetup() {
    if (!this.platform.is('ios') && !this.platform.is('android')) return;
    
    this.subscriptions.push(
      this.fcm.onNotificationPressed.subscribe(async (msg) => {
        Haptics.vibrate({ duration: 1000 });
        
        if (!!msg.notification.data.type) {
          await this.handleNotification(msg.notification.data.type, msg.notification.data);
        }
      })
    );
  }

  private async handleNotification(type: string, notificationData: {[key: string]: string}) {
    if (notificationData.hasOwnProperty('shipId') && this.appSettings.ship?.Id != parseInt(notificationData['shipId'])) {
      const ships = await this.chooseShipService.retrieve();
      const shipId = parseInt(notificationData['shipId']);
      const ship = ships.find(x => x.Id == shipId);
      if (!!ship) {
        this.appSettings.ship = ship;
      }
    }

    switch (type) {
      case Constants.notificationTypes.certifcateExpiresSoon: {
        
        this.navController.navigateForward('/home/certificates');
        break;
      }
      case Constants.notificationTypes.newTask: {
        break;
      }
      case Constants.notificationTypes.newMaintenanceTask: {
        break;
      }
    }
  }

  private async navigateToInitialRoute() {
    if (!this.authenticationService.hasValidAccessToken()) {
      if (this.appSettings.rememberMe) {
        try {
          const result = await this.authenticationService.refresh();
          this.authenticationService.saveAccessTokens(result);
        } catch (exception) {
          this.navController.navigateRoot('/account/login');
        }
      } else {
        this.navController.navigateRoot('/account/login');
      }
    }
  }

  private async keepLoginAlive() {
    const sleep = (m: number) => new Promise((r) => setTimeout(r, m));
    await sleep(1000 * 60 * 5);
    await this.authenticationService.refresh();
    this.keepLoginAlive();
  }
}
