import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/classes/BaseComponent';
import { NavController } from '@ionic/angular';
import { CertifcatesService } from './certifcates.service';
import * as moment from 'moment';
import {
  Directory,
  Encoding,
  Filesystem,
  GetUriResult,
} from '@capacitor/filesystem';
import {
  FileOpener,
  FileOpenerOptions,
} from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { DownloadHelperService } from '../../services/download-helper.service';
import { ICertificate } from '../../shared/models/Certificate';
import { Constants } from '../../constants';

@Component({
  selector: 'certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss'],
})
export class CertificatesComponent extends BaseComponent implements OnInit {
  certificates?: ICertificate[];
  shipCertificates?: ICertificate[];

  today = moment();

  constructor(
    navController: NavController,
    private certificateService: CertifcatesService,
    private downloadHelperService: DownloadHelperService
  ) {
    super(navController);
  }

  ngOnInit() {
    this.certificateService.list().then((result) => {
      this.shipCertificates = result.filter((x) => x.IsShip);
      this.certificates = result.filter((x) => !x.IsShip);
    });
  }

  checkExpiration(certifcate: ICertificate) {
    const certifcateExpirationDate = moment(certifcate.ValidUntil);
    const notificationDate = moment(certifcate.NotificationDate);
    const dateDiff = certifcateExpirationDate.diff(this.today, 'days');

    if (
      (dateDiff > 0 && dateDiff <= 15) ||
      (this.today > notificationDate && this.today < certifcateExpirationDate)
    ) {
      return 'almost-expired';
    } else if (certifcateExpirationDate >= this.today) {
      return 'valid';
    }

    return 'expired';
  }

  async showCertificate(certificate: ICertificate) {
    var filenameSplit = certificate.Certificate?.split('/');
    var filename = filenameSplit ? filenameSplit[filenameSplit.length - 1] : '';

    let filePath: string | undefined = undefined;
    if (!Capacitor.isNativePlatform()) {
      filePath = Constants.UploadPath + certificate.Certificate
    } else if (
      certificate.hasOwnProperty('Certificate') &&
      certificate.Certificate != null &&
      certificate.Certificate != ''
    ) {
      filePath = (await Filesystem.getUri({
        path: 'cert-' + filename,
        directory: Directory.Data,
      })).uri;
    } else if (
      certificate.hasOwnProperty('LocalUrl') &&
      !!certificate.LocalUrl
    ) {
      filePath = (await Filesystem.getUri({
        path: certificate.LocalUrl,
        directory: Directory.Data,
      })).uri;
    }
    

    if (filePath)
    {
      this.downloadHelperService.download(filePath, filename, 'application/pdf');
    }    
  }

}