import { Injectable } from '@angular/core';
import {
  FileOpenerOptions,
  FileOpener,
} from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class DownloadHelperService {
  constructor() {}

  async download(
    filePath: string,
    fileName: string,
    contentType: string
  ) {
    if (!Capacitor.isNativePlatform()) {
      return this.downloadWebFile(filePath, fileName);
    }

    await this.downloadNativeFile(filePath, fileName, contentType);
  }

  private async downloadNativeFile(
    filePath: string,
    fileName: string,
    contentType: string
  ) {
    const fileOpenerOptions: FileOpenerOptions = {
      filePath: filePath,
      contentType: contentType,
      openWithDefault: true,
    };
    
    await FileOpener.open(fileOpenerOptions)
    .then(() => {
      // 'File is opened'
    })
    .catch((error) => {
      console.error(error);
    });
  }

  private downloadWebFile(
    filePath: string,
    fileName: string
  ) {
    var webUrl = environment.baseUrl + filePath;
    var a = document.createElement('a');

    a['href'] = webUrl; // xhr.response is a blob
    a['download'] = fileName; // Set the file name.
    a['target'] = '_blank';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return;
  }
}