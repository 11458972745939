import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Constants } from '../constants';
import { BehaviorSubject, Subject, distinctUntilChanged } from 'rxjs';
import { IShip } from '../shared/models/Ship';
import { IJourneyReport } from "../shared/models/JourneyReport";
import { IWorkOrder } from '../shared/models/WorkOrder';
import { IHandOver } from '../shared/models/HandOver';

@Injectable({
    providedIn: 'root'
})
export class LocalAppSettingsService {

    constructor(private storageService: LocalStorageService) { }

    private languageSubject = new Subject<any>();
    private shipSubject = new BehaviorSubject<IShip | null>(this.ship);

    getLanguageObservable() {
        return this.languageSubject.asObservable();
    }

    getShipObservable() {
        return this.shipSubject.pipe(distinctUntilChanged((x, y) => x?.Id == y?.Id));
    }

    removeAccessTokens() {
        this.storageService.remove(Constants.storageKeys.accessToken);
        this.storageService.remove(Constants.storageKeys.refreshToken);
        this.storageService.remove(Constants.storageKeys.accessTokenExpiresAt);
    }

    logout(){
        this.removeAccessTokens();
        this.userId = null
        this.username = ''
        this.ship = null
        this.currentHandover = null;
        this.currentWorkOrderListData = null;
        this.currentJourneyReportListData = null;
        this.currentWorkOrder = null;
        this.currentJourneyReport = null;
    }


    get selectedLanguage(): string {
        return this.storageService.get<string>(Constants.storageKeys.selectedLanguage, "nl");
    }

    set selectedLanguage(language: string) {
        this.storageService.set(Constants.storageKeys.selectedLanguage, language);
        this.languageSubject.next(language);
    }

    get accessToken(): string {
        return this.storageService.get<string>(Constants.storageKeys.accessToken);
    }

    set accessToken(accessToken: string) {
        this.storageService.set(Constants.storageKeys.accessToken, accessToken);
    }

    get rememberMe(): boolean {
        return this.storageService.get<boolean>(Constants.storageKeys.rememberMe, false);
    }

    set rememberMe(rememberMe: boolean) {
        this.storageService.set(Constants.storageKeys.rememberMe, rememberMe);
    }

    get accessTokenExpiresAt(): number {
        return this.storageService.get<number>(Constants.storageKeys.accessTokenExpiresAt);
    }

    set accessTokenExpiresAt(expiresAt: number) {
        this.storageService.set(Constants.storageKeys.accessTokenExpiresAt, expiresAt);
    }

    get refreshToken(): string {
        return this.storageService.get<string>(Constants.storageKeys.refreshToken);
    }

    set refreshToken(refreshToken: string) {
        this.storageService.set(Constants.storageKeys.refreshToken, refreshToken);
    }

    get ship(): IShip {
        return this.storageService.get<IShip>(Constants.storageKeys.ship);
    }

    set ship(ship: IShip | null) {
        this.storageService.set<IShip | null>(Constants.storageKeys.ship, ship);
        this.shipSubject.next(ship);
    }

    get username(): string {
        return this.storageService.get<string>(Constants.storageKeys.username);
    }

    set username(username: string) {
        this.storageService.set(Constants.storageKeys.username, username);
    }

    get userId(): number | null {
        return this.storageService.get<number | null>(Constants.storageKeys.userId);
    }

    set userId(userId: number | null) {
        this.storageService.set(Constants.storageKeys.userId, userId);
    }

    get pushNotificationsEnabled(): boolean {
        return this.storageService.get<boolean>(Constants.storageKeys.pushEnabled);
    }

    set pushNotificationsEnabled(pushNotificationsEnabled: boolean) {
        this.storageService.set(Constants.storageKeys.pushEnabled, pushNotificationsEnabled);
    }
    
    get currentJourneyReport(): IJourneyReport | null {
        return this.storageService.get<IJourneyReport | null>(Constants.storageKeys.tempJourneyReport);
    }

    set currentJourneyReport(journeyReport: IJourneyReport | null) {
        this.storageService.set(Constants.storageKeys.tempJourneyReport, journeyReport);
    }

    get currentJourneyReportListData(): {} | null {
        return this.storageService.get<{} | null>(Constants.storageKeys.tempJourneyReportData);
    }

    set currentJourneyReportListData(data: {} | null) {
        this.storageService.set(Constants.storageKeys.tempJourneyReportData, data);
    }

    get currentWorkOrder(): IWorkOrder | null {
        return this.storageService.get<IWorkOrder | null>(Constants.storageKeys.tempWorkOrder);
    }

    set currentWorkOrder(workOrder: IWorkOrder | null) {
        this.storageService.set(Constants.storageKeys.tempWorkOrder, workOrder);
    }

    get currentWorkOrderListData(): {} | null {
        return this.storageService.get<{} | null>(Constants.storageKeys.tempWorkOrderData);
    }

    set currentWorkOrderListData(data: {} | null) {
        this.storageService.set(Constants.storageKeys.tempWorkOrderData, data);
    }

    get currentHandover(): IHandOver | null {
        return this.storageService.get<IHandOver | null>(Constants.storageKeys.tempHandover);
    }

    set currentHandover(handover: IHandOver | null) {
        this.storageService.set(Constants.storageKeys.tempHandover, handover);
    }
}
