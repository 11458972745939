import { NavController, AlertController } from '@ionic/angular';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from "moment";
import { IComponentTaskDetailModel } from 'src/app/shared/models/IComponentTaskDetailModel';
import { BaseFormComponent } from 'src/app/shared/classes/BaseFormComponent';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { IComponentTaskHoursForm } from 'src/app/shared/models/IComponentTaskHoursForm'
import { TaskService } from 'src/app/services/task.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'component-task-engine-hours',
    templateUrl: './task-engine-hours.component.html',
    styleUrls: ['./task-engine-hours.component.scss'],
})
export class ComponentTaskHoursComponent extends BaseFormComponent implements OnInit {

    @Input() task!: IComponentTaskDetailModel;
    @Input() index: Number = 0;
    @Output() readingSentEvent = new EventEmitter<string>();
    currentLanguage: string = '';
    toolTipId : string = '';

    constructor(navController: NavController, 
        private alertController: AlertController,
        private translateService: TranslateService,
        private taskService: TaskService,
        private appSettings: LocalAppSettingsService,
        private route: ActivatedRoute) {
        super(navController);
    }

    ngOnInit() {
        var readingDate: Date | string = this.today.toISOString();
        let employeeName = '';
        let quantity = '';
        let shipReading = '';
        this.currentLanguage = this.appSettings.selectedLanguage;
        let remark = '';

        this.form = new FormGroup(
            {
                readingDate: new FormControl(readingDate, [Validators.required]),
                employeeName: new FormControl(employeeName, [Validators.required]),
                quantity: new FormControl(quantity, [Validators.required]),
                shipReading: new FormControl(shipReading, [Validators.required, Validators.min(1)]),
                remark: new FormControl(remark)
            }
        );

    }

    getYear(date: Date): number {
        return moment(date).year();
    }

    async submit(reading: IComponentTaskHoursForm) {
        if (!this.form.valid) {            
            return;
        }

        const result = await this.disableFormDuringApiCall(() =>{
            reading.taskId = this.task.Id;   
            this.taskService.postTaskHourReading(reading).then(async (result) => { 
                const alert = await this.alertController.create({
                    header: this.translateService.instant('urenstand_opgeslagen_title'),
                    message: this.translateService.instant('urenstand_opgeslagen_description'),
                    buttons: [this.translateService.instant('ok')]
                });

                await alert.present();

                this.readingSentEvent.emit();
            });            
        });
    }

}