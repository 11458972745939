<home-toolbar [showBackButton]="true" [title]="'componenten' | translate"></home-toolbar>

<ion-content>
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <div class="container">
        <h1 class="mb-3">{{componentName}}</h1>

        <div class="col-12 pl-0 mb-5" *ngIf="showGlobalHourForm()">
            <div>
                <h2>{{'Nieuwe_stand' | translate}}</h2>
            </div>
            <div class="col-12">
                <form [formGroup]="form" class="row" (ngSubmit)="form.valid && submit(form.value)" autocomplete="off">
                    <div class="col-md-3 mb-2 col-sm-12">
                        <ion-datetime-button
                            name="readingDateButton"
                            id="readingDateButton"
                            datetime="readingDate"
                            class="input-text justify-content-start flex-nowrap text-nowrap"></ion-datetime-button>

                        <ion-modal [keepContentsMounted]="true" #departmentDateModal>
                            <ng-template>
                              <ion-datetime
                                id="readingDate"
                                presentation="date"
                                name="readingDate"
                                formControlName="readingDate"
                                [cancelText]="'annuleren' | translate"
                                [doneText]="'klaar' | translate"
                                [showDefaultButtons]="true"
                                [locale]="currentLanguage"
                                [formatOptions]="{
                                    date: {
                                      weekday: 'short',
                                      month: 'long',
                                      day: '2-digit',
                                    }
                                  }"
                              >
                              </ion-datetime>
                            </ng-template>
                          </ion-modal>
                    </div>
                    <div class="col-md-3 mb-2 col-sm-12">
                        <input class="input-text w-100 autocomplete"
                                type="text"
                                [placeholder]="'Naam' | translate"
                                formControlName="employeeName" />
                    </div>
                    <div class="col-md-3 mb-2 col-sm-12">
                        <input class="input-text w-100 autocomplete"
                        type="text"
                        [placeholder]="'uren_stand' | translate"
                        [value]="" formControlName="shipReading" />
                    </div>
                    <div class="col-md-3 mb-2 col-sm-12">
                        <button class="btn-login" type="submit">{{'Opslaan' | translate}}</button>
                    </div>
                </form>
            </div>
        </div>

        <h2>{{'Taken' | translate}}</h2>
        <div *ngFor="let task of componentDetails; let i = index" class="component-item">
            <component-task-engine-hours *ngIf="task.IntervalType == 0" (readingSentEvent)="readingSent()" [index]="i" [task]="task"></component-task-engine-hours>
            <component-task-time [task]="task" (readingSentEvent)="readingSent()" *ngIf="task.IntervalType > 0"></component-task-time>
        </div>

    </div>
</ion-content>