export const Constants = {
    storageKeys: {
        selectedLanguage: "selected_language",
        accessToken: "access_token",
        accessTokenExpiresAt: "access_token_expires_at",
        refreshToken: "refresh_token",
        rememberMe: "remember_me",
        ship: "ship",
        username: "username",
        userId: "user_id",
        pushEnabled: "push_enabled",
        tempJourneyReport: "tempJourneyReport",
        tempJourneyReportData: "tempJourneyReportData",
        tempWorkOrder: "tempWorkOrder",
        tempWorkOrderData: "tempWorkOrderData",
        tempHandover: 'tempHandOver'
    },
    messageKeys: {
        repairAdded: "repair_added",
        repairChanged: "repair_changed",
    },
    sqlStorageKeys: {
        journeyReports: "sql_journey_reports",
        shipEngines: "sql_ship_engines",
        shipLayout: "sql_ship_layout_", // suffix with an id.
        shipEngineComponents: "sql_ship_engine_components",
        shipEngineReadings: "sql_ship_engine_readings_", // suffix with an id.
        shipEngineReadingHours: "sql_ship_engine_readings_hours_", // suffix with an id.
        shipEngineComponentReadings: "sql_ship_engine_component_readings_", // suffix with an id.
        shipComponentZones: "sql_ship_component_zones_",
        shipComponentDetails: "sql_ship_component_details_",
        repairs: "sql_repairs",
        certificates: "sql_certificates",
        storedRequests: "sql_stored_requests",
        ports: "sql_ports",
        workOrderCompanies: "sql_workorder_companies",
        cargo: "sql_cargo",
        tasks: "sql_tasks",
        settings: "sql_settings",
        schedule: "sql_schedule",
        shipRoles: "sql_shiproles",
        liveLocations: "sql_livelocations",
        workOrders: "sql_workorders",
        handover: "sql_handover",
    },
    notificationTypes: { 
        certifcateExpiresSoon: "certificate-expired",
        newTask: "new-task",
        newMaintenanceTask: "new-MaintenanceTask"
    },
    regexes: {
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
        currency: /^(\d+(?:[\.\,]\d{1,2})?)$/,
        digits: /^\d+$/
    },
    componentStatus: {
        Ok: 1,
        Warning: 2,
        Overdue: 3
    },
    TankerHandoverFields: ['stateChargeTanks', 'pipesHoses', 'cleaningGasFree', 'slobtanksBb', 'slobtanksMid', 'slobtanksSb', 'rinsingWaterTankBb', 'rinsingWaterTankSb','rinsingWaterTankPeak', 'rinsingWaterTankBallast', 'tankLevelEquipment', 'boiler', 'tracing', 'crewMatrixUpdated'],
    DryLoadHandoverFields: ['maintenanceUpdated', 'journeyReportUpdated', 'repairListUpdated', 'rinsingWaterTankBallast'],
    IntervalTypes: {
        ComponentHours: 0,
        Once: 1,
        Weekly: 12,
        TwoWeekly: 24,
        Monthly: 30,
        Yearly: 52
    },
    UploadPath: 'upload/'
}
